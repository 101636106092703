<template>
  <Image
    v-if="imageUrl"
    :src="imageUrl"
    :alt="altText"
    class="object-scale-down"
  />
  <img
    v-else-if="$siteIdent.startsWith('minilu')"
    :alt="t('shop.products.imageAlt')"
    class="object-scale-down placeholder-product-img"
    src="@/assets/images/minilu/pink-package-icon.svg"
  />
  <img
    v-else-if="!$siteIdent.startsWith('minilu')"
    :alt="t('shop.products.imageAlt')"
    class="object-scale-down placeholder-product-img"
    src="@/assets/images/vdv/green-package-icon.svg"
  />
</template>

<script setup lang="ts">
import { Image } from '@/complib';

defineProps({
  imageUrl: {
    type: String,
    required: false,
    default: null,
  },
  altText: {
    type: String,
    required: false,
    default: 'product-image',
  },
});

const { t } = useTrans();
</script>
